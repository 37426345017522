import React, { useRef, useState, useCallback, useEffect } from "react";

import {
  Icon,
  Form,
  Button,
  Checkbox,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import {
  useSensor,
  useSensors,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { useMapsLibrary } from "@vis.gl/react-google-maps";

import SortableItem from "app/pages/main/sortable";

import { getFormattedAddress } from "app/utils/helpers";

const placeOptions = {
  componentRestrictions: { country: "us" },
  fields: ["geometry", "name", "formatted_address"],
};

const ManageLocations = ({ trip, onSave, onClose }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const pickupRef = useRef();
  const dropoffRef = useRef();

  const places = useMapsLibrary("places");

  const [placePickup, setPlacePickup] = useState();
  const [placeDropoff, setPlaceDropoff] = useState();

  const [type, setType] = useState();
  const [chargeNeeded, setChargeNeeded] = useState(false);
  const [locations, setLocations] = useState([]);
  const [stopLocations, setStopLocations] = useState([]);
  const [pickupLocation, setPickupLocation] = useState({
    id: uuidv4(),
    name: null,
    type: "pickup",
    latitude: null,
    longitude: null,
  });
  const [dropoffLocation, setDropoffLocation] = useState({
    id: uuidv4(),
    name: null,
    type: "dropoff",
    latitude: null,
    longitude: null,
  });

  const [validations, setValidations] = useState({});

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setStopLocations((locations) => {
        const oldIndex = locations.findIndex((l) => l.id === active.id);
        const newIndex = locations.findIndex((l) => l.id === over.id);

        return arrayMove(locations, oldIndex, newIndex);
      });
    }
  }, []);

  const handleRemoveStop = useCallback((id) => {
    setStopLocations((locations) => {
      return locations.filter((l) => l.id !== id);
    });
  }, []);

  const onStopLocationUpdate = useCallback((id, update) => {
    setStopLocations((locations) => {
      const index = locations.findIndex((l) => l.id === id);

      locations[index] = update;

      return [...locations];
    });
  }, []);

  useEffect(() => {
    if (!trip) {
      return;
    }

    const { type, locations } = trip;

    const pickup = locations.find((l) => l.type === "pickup");

    if (pickup) {
      setPickupLocation(pickup);
    }

    const dropoff = locations.find((l) => l.type === "dropoff");

    if (dropoff) {
      setDropoffLocation(dropoff);
    }

    const stops = locations
      .filter((l) => l.type === "stop")
      .map((l) => ({
        id: l.id,
        type: l.type,
        name: l.name,
        handled: l.handled,
        latitude: l.latitude,
        longitude: l.longitude,
      }));

    setType(type);
    setStopLocations(stops);
    setLocations(locations);
  }, []);

  useEffect(() => {
    if (!places || !pickupRef.current) {
      return;
    }

    setPlacePickup(new places.Autocomplete(pickupRef.current, placeOptions));
  }, [places, pickupRef]);

  useEffect(() => {
    if (!places || !dropoffRef.current) {
      return;
    }

    setPlaceDropoff(new places.Autocomplete(dropoffRef.current, placeOptions));
  }, [places, dropoffRef]);

  useEffect(() => {
    if (!placePickup) return;

    placePickup.addListener("place_changed", () => {
      const place = placePickup.getPlace();

      if (place.geometry && place.geometry.location) {
        setPickupLocation({
          type: "pickup",
          name: getFormattedAddress(place),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
      }
    });
  }, [placePickup]);

  useEffect(() => {
    if (!placeDropoff) return;

    placeDropoff.addListener("place_changed", () => {
      const place = placeDropoff.getPlace();

      if (place.geometry && place.geometry.location) {
        setDropoffLocation({
          id: uuidv4(),
          type: "dropoff",
          name: getFormattedAddress(place),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
      }
    });
  }, [placeDropoff]);

  useEffect(() => {
    if (pickupRef.current) {
      pickupRef.current.value = pickupLocation.name;
    }

    if (dropoffRef.current) {
      dropoffRef.current.value = dropoffLocation.name;
    }
  }, [type, pickupRef, dropoffRef, pickupLocation, dropoffLocation]);

  return (
    <Modal size={"tiny"} open onClose={onClose}>
      <ModalHeader>Add Stop</ModalHeader>
      <ModalContent>
        <Form loading={false} autoComplete="off">
          <Form.Input
            fluid
            disabled
            // required
            input={{ ref: pickupRef }}
            icon="map marker alternate"
            // value={trip.pickup}
            iconPosition="left"
            autocomplete="off"
            placeholder="Pickup Location"
            onChange={(event, data) => {
              if (data.value === "") {
                setPickupLocation({
                  name: null,
                  type: "pickup",
                  latitude: null,
                  longitude: null,
                });
              }
            }}
            error={
              validations.pickup
                ? {
                    content: validations.pickup,
                  }
                : false
            }
          />

          <div style={{ marginBottom: 20 }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={stopLocations}
                strategy={verticalListSortingStrategy}
              >
                {stopLocations.map((location) => (
                  <SortableItem
                    key={location.id}
                    item={location}
                    places={places}
                    onDelete={handleRemoveStop}
                    onLocationUpdate={onStopLocationUpdate}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
            <span
              style={{
                fontSize: "1.1em",
                color: "black",
                fontWeight: 600,
                cursor: "pointer",
                borderBottomWidth: 1,
                borderBottomColor: "black",
                borderBottomStyle: "groove",
              }}
            >
              <Icon name="plus" style={{ margin: 0, fontSize: "1em" }} />
              <span
                onClick={() => {
                  setStopLocations((state) => [
                    ...state,
                    {
                      id: uuidv4(),
                      name: "",
                      type: "stop",
                      latitude: null,
                      longitude: null,
                    },
                  ]);
                }}
              >
                Add Stop
              </span>
            </span>
          </div>

          {type === "one_time" && (
            <Form.Input
              fluid
              // required
              input={{ ref: dropoffRef }}
              icon="map pin"
              // value={dropoff}
              style={{
                marginBottom: 20,
              }}
              iconPosition="left"
              autocomplete="off"
              placeholder="Dropoff Location"
              // autoComplete="new-password"
              onChange={(event, data) => {
                if (data.value === "") {
                  setDropoffLocation({
                    name: null,
                    type: "dropoff",
                    latitude: null,
                    longitude: null,
                  });
                }
              }}
              // type="text"
              error={
                validations.dropoff
                  ? {
                      content: validations.dropoff,
                    }
                  : false
              }
            />
          )}

          <Checkbox
            checked={chargeNeeded}
            style={{ fontSize: "1.2em" }}
            label="Pre authorize charge for this request"
            onChange={(e, data) => setChargeNeeded(data.checked)}
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="black"
          onClick={() => {
            onSave({
              type,
              charge_needed: chargeNeeded,
              locations: dropoffLocation.name
                ? [pickupLocation, ...stopLocations, dropoffLocation]
                : [pickupLocation, ...stopLocations],
            });
          }}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ManageLocations;
