import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import {
  useSensor,
  useSensors,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { useNavigate } from "react-router-dom";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

import { Map, Marker, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  Icon,
  Form,
  Grid,
  Header,
  Button,
  Message,
  Dropdown,
  GridColumn,
  ButtonGroup,
  MessageContent,
} from "semantic-ui-react";

import Loading from "app/components/loading";
import SortableItem from "app/pages/main/sortable";
import CityToCity from "app/pages/main/city-to-city";

import { useValidations } from "app/services/validations/context";
import { useGlobal } from "app/services/global/context";

import { handleApiError } from "app/utils/errors";
import { getFormattedAddress } from "app/utils/helpers";

const placesOptions = {
  componentRestrictions: { country: "us" },
  fields: ["geometry", "name", "formatted_address"],
};

const periodOptions = [
  { key: 2, text: "2 hours", value: 2 },
  { key: 3, text: "3 hours", value: 3 },
  { key: 4, text: "4 hours", value: 4 },
  { key: 5, text: "5 hours", value: 5 },
  { key: 6, text: "6 hours", value: 6 },
  { key: 7, text: "7 hours", value: 7 },
  { key: 8, text: "8 hours", value: 8 },
  { key: 9, text: "9 hours", value: 9 },
  { key: 10, text: "10 hours", value: 10 },
  { key: 12, text: "12 hours", value: 12 },
  { key: 13, text: "13 hours", value: 13 },
  { key: 14, text: "14 hours", value: 14 },
  { key: 15, text: "15 hours", value: 15 },
  { key: 16, text: "16 hours", value: 16 },
  { key: 17, text: "17 hours", value: 17 },
  { key: 18, text: "18 hours", value: 18 },
  { key: 19, text: "19 hours", value: 19 },
  { key: 20, text: "20 hours", value: 20 },
  { key: 21, text: "21 hours", value: 21 },
  { key: 22, text: "22 hours", value: 22 },
  { key: 23, text: "23 hours", value: 23 },
  { key: 24, text: "24 hours", value: 24 },
];

const Order = () => {
  const map = useMap("map");
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const places = useMapsLibrary("places");

  const { onValidateCreateTrip } = useValidations();
  const {
    user,
    form,
    onForm,
    dateToday,
    klassOptions,
    klassDisplay,
    onKlassDisplay,
    passengerOptions,
    onPassengerOptions,
    authenticated,
    onPendingTrip,
    onCreateTrip,
    onEstimateTrip,
    onFetchPassengers,
    onEstimateDistanceTrip,
    onCreateTripPassengers,
  } = useGlobal();

  const timeOptions = useMemo(() => {
    const options = [];
    const dt = DateTime.now();

    let date = dt.set({ hour: 0, minute: 0 });

    for (var i = 0; i < 96; i++) {
      options.push(date.toLocaleString({ hour: "numeric", minute: "2-digit" }));

      date = date.plus({ minute: 15 });
    }

    return options;
  }, []);

  const pickupRef = useRef();
  const dropoffRef = useRef();

  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeIndex, setRouteIndex] = useState(0);

  const [type, setType] = useState("hourly");

  // const dateToday = DateTime.now().setZone("America/Los_Angeles");

  const [bounds, setBounds] = useState();
  const [locations, setLocations] = useState([]);
  const [stopLocations, setStopLocations] = useState([]);
  const [pickupLocation, setPickupLocation] = useState({
    id: uuidv4(),
    name: null,
    type: "pickup",
    latitude: null,
    longitude: null,
  });
  const [dropoffLocation, setDropoffLocation] = useState({
    id: uuidv4(),
    name: null,
    type: "dropoff",
    latitude: null,
    longitude: null,
  });

  const [placePickup, setPlacePickup] = useState();
  const [placeDropoff, setPlaceDropoff] = useState();

  const [formSize, setFormSize] = useState("large");

  const [price, setPrice] = useState(null);
  const [displayDistance, setDisplayDistance] = useState(null);

  const [selectedDay, setSelectedDay] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showPickupTime, setShowPickupTime] = useState(false);

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [period, setPeriod] = useState(2);
  const [currentDate, setNewDate] = useState(null);

  const [validations, setValidations] = useState({});

  const onChange = (event, data) => setNewDate(data.value);

  const [zoom, setZoom] = useState(12);
  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [pickupPosition, setPickupPosition] = useState();
  const [dropoffPosition, setDropoffPosition] = useState();

  const applyChanges = useCallback((changes) => {
    onForm((form) => ({ ...form, ...changes }));
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setStopLocations((locations) => {
        const oldIndex = locations.findIndex((l) => l.id === active.id);
        const newIndex = locations.findIndex((l) => l.id === over.id);

        return arrayMove(locations, oldIndex, newIndex);
      });
    }
  }, []);

  const handleRemoveStop = useCallback((id) => {
    setStopLocations((locations) => {
      return locations.filter((l) => l.id !== id);
    });
  }, []);

  const onStopLocationUpdate = useCallback((id, update) => {
    setStopLocations((locations) => {
      const index = locations.findIndex((l) => l.id === id);

      locations[index] = update;

      return [...locations];
    });
  }, []);

  const estimateDistanceTrip = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onEstimateDistanceTrip({
          trip: {
            locations_attributes: locations,
          },
        });

        const { distance, display_distance } = response.data;

        applyChanges({ distance });
        setDisplayDistance(display_distance);
      } catch (e) {
        // handleApiError(e, setValidations);
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [locations]);

  const estimateTripCharge = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const {
          type,
          klass,
          distance,
          number_of_passengers,
          trip_hours_attributes,
        } = form;

        const response = await onEstimateTrip({
          trip: {
            type,
            klass,
            distance,
            number_of_passengers,
            trip_hours_attributes,
            number_of_stops: locations.filter(
              (l) => l.name && l.type === "stop"
            ).length,
          },
        });

        const { charge } = response.data;

        setPrice(charge);
      } catch (e) {
        // handleApiError(e, setValidations);
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [form, period, locations]);

  const handleBookNow = useCallback(() => {
    const requestAsync = async () => {
      try {
        setSubmitting(true);

        await onValidateCreateTrip({
          trip: form,
        });

        if (!authenticated) {
          onPendingTrip(true);

          navigate("/login");

          return;
        }

        const response = await onCreateTrip({
          trip: form,
        });

        const { trip } = response.data;

        const { id, charges } = trip;

        const {
          data: { passengers = [] },
        } = await onFetchPassengers();

        if (passengers.length > 0) {
          await onCreateTripPassengers(id, {
            trip: { passenger_ids: [passengers[0].id] },
          });
        }

        navigate(`/trips/${trip.id}/charges/${charges[0].id}/payment`);
      } catch (e) {
        handleApiError(e, setValidations);
      } finally {
        setSubmitting(false);
      }
    };

    requestAsync();
  }, [form, user, authenticated]);

  const processRouteChanges = useCallback(() => {
    const requestAsync = async () => {
      try {
        let waypoints = [];
        let destination;

        const pickupLocation = locations.find(
          (location) => location.type === "pickup"
        );

        const dropoffLocation = locations.find(
          (location) => location.type === "dropoff"
        );

        const stopLocations = locations.filter(
          (location) => location.type === "stop"
        );

        if (!pickupLocation) {
          return;
        }

        const origin = `${pickupLocation.latitude},${pickupLocation.longitude}`;
        const lastStop = stopLocations[stopLocations.length - 1];

        if (dropoffLocation) {
          destination = `${dropoffLocation.latitude},${dropoffLocation.longitude}`;
          waypoints = stopLocations.map((l) => ({
            stopover: true,
            location: `${l.latitude},${l.longitude}`,
          }));
        } else if (lastStop && lastStop.name) {
          destination = `${lastStop.latitude},${lastStop.longitude}`;
          waypoints = stopLocations
            .filter((l) => l.name !== lastStop.name)
            .map((l) => ({
              stopover: true,
              location: `${l.latitude},${l.longitude}`,
            }));
        }

        if (!origin || !destination) {
          return;
        }

        const response = await directionsService.route({
          origin,
          waypoints,
          destination,
          // Google will mix waypoints
          // Make optimizeWaypoints false
          optimizeWaypoints: false,
          travelMode: "DRIVING", //"WALKING",
          // provideRouteAlternatives: true,
        });

        directionsRenderer.setDirections(response);

        setRoutes(response.routes);

        console.log("roaute are changed", response.routes);
      } catch (e) {
        console.log(e);
      }
    };

    requestAsync();
  }, [locations, directionsService, directionsRenderer]);

  useEffect(() => {
    if (!form) {
      return;
    }

    const { type, pickup_date, locations_attributes, trip_hours_attributes } =
      form;

    setType(type);

    const pickup = locations_attributes.find((l) => l.type === "pickup");

    if (pickup) {
      setPickupLocation(pickup);
    }

    const dropoff = locations_attributes.find((l) => l.type === "dropoff");

    if (dropoff) {
      setDropoffLocation(dropoff);
    }

    const stops = locations_attributes
      .filter((l) => l.type === "stop")
      .map((l) => ({
        id: uuidv4(),
        type: l.type,
        name: l.name,
        latitude: l.latitude,
        longitude: l.longitude,
      }));

    setStopLocations(stops);

    const trip_hours = trip_hours_attributes[0];

    if (trip_hours) {
      setPeriod(trip_hours.hours);
    }
  }, []);

  useEffect(() => {
    if (!dateToday) {
      return;
    }

    setSelectedDay({
      day: dateToday.day,
      month: dateToday.month,
      year: dateToday.year,
    });
  }, [dateToday]);

  useEffect(() => {
    setValidations({});
  }, [pickupLocation, stopLocations, dropoffLocation]);

  useEffect(() => {
    setDisplayDistance(null);
    applyChanges({ distance: null });
  }, [type, pickupLocation, stopLocations, dropoffLocation]);

  useEffect(() => {
    const locations = [];

    if (pickupLocation.name) {
      locations.push({
        type: pickupLocation.type,
        name: pickupLocation.name,
        latitude: pickupLocation.latitude,
        longitude: pickupLocation.longitude,
      });
    }

    stopLocations.forEach((location) => {
      if (location.name) {
        locations.push({
          type: location.type,
          name: location.name,
          latitude: location.latitude,
          longitude: location.longitude,
        });
      }
    });

    if (type === "one_time" && dropoffLocation.name) {
      locations.push({
        type: "dropoff",
        name: dropoffLocation.name,
        latitude: dropoffLocation.latitude,
        longitude: dropoffLocation.longitude,
      });
    }

    setLocations(locations);
    applyChanges({ locations_attributes: locations });
  }, [type, pickupLocation, stopLocations, dropoffLocation]);

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [routesLibrary, map]);

  useEffect(() => {
    if (pickupRef.current) {
      pickupRef.current.value = pickupLocation.name;
    }

    if (dropoffRef.current) {
      dropoffRef.current.value = dropoffLocation.name;
    }
  }, [type, pickupRef, dropoffRef, pickupLocation, dropoffLocation]);

  useEffect(() => {
    if (!directionsRenderer) {
      return;
    }

    return () => directionsRenderer.setMap(null);
  }, [directionsRenderer]);

  useEffect(() => {
    if (locations.length < 2) {
      setDisplayDistance(null);

      return;
    }

    estimateDistanceTrip();
  }, [locations]);

  // Use directions service
  useEffect(() => {
    if (locations.length < 2) {
      setRoutes([]);
      applyChanges({ distance: 0 });

      return;
    }

    if (!directionsService || !directionsRenderer) {
      return;
    }

    processRouteChanges();
  }, [locations, directionsService, directionsRenderer]);

  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;

    if (routes.length === 0) {
      directionsRenderer.setRouteIndex(-1);

      return;
    }

    directionsRenderer.setRouteIndex(routeIndex);
  }, [routes, routeIndex, directionsRenderer]);

  useEffect(() => {
    if (!map || !places || !pickupRef.current) {
      return;
    }

    setPlacePickup(new places.Autocomplete(pickupRef.current, placesOptions));
  }, [map, places, pickupRef]);

  useEffect(() => {
    if (!map || !places || !dropoffRef.current) {
      return;
    }

    setPlaceDropoff(new places.Autocomplete(dropoffRef.current, placesOptions));
  }, [map, type, places, dropoffRef]);

  useEffect(() => {
    if (!placePickup) return;

    placePickup.addListener("place_changed", () => {
      const place = placePickup.getPlace();

      if (place.geometry && place.geometry.location) {
        setPickupLocation({
          type: "pickup",
          name: getFormattedAddress(place),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });

        setBounds(place.geometry.viewport);
      }
    });
  }, [placePickup]);

  useEffect(() => {
    if (!placeDropoff) return;

    placeDropoff.addListener("place_changed", () => {
      const place = placeDropoff.getPlace();

      if (place.geometry && place.geometry.location) {
        setDropoffLocation({
          id: uuidv4(),
          type: "dropoff",
          name: getFormattedAddress(place),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
      }
    });
  }, [placeDropoff]);

  useEffect(() => {
    if (!map || !bounds || !pickupLocation.name) {
      return;
    }

    setPickupPosition({
      lat: pickupLocation.latitude,
      lng: pickupLocation.longitude,
    });

    map.fitBounds(bounds);
  }, [map, bounds, pickupLocation]);

  useEffect(() => {
    if (!pickupLocation.name) {
      return;
    }

    setPickupPosition({
      lat: pickupLocation.latitude,
      lng: pickupLocation.longitude,
    });
  }, [pickupLocation]);

  useEffect(() => {
    if (!dropoffLocation.name) {
      return;
    }

    setDropoffPosition({
      lat: dropoffLocation.latitude,
      lng: dropoffLocation.longitude,
    });
  }, [dropoffLocation]);

  useEffect(() => {
    if (
      form.type &&
      form.klass &&
      ((form.type === "hourly" && period && form.distance !== null) ||
        (form.type === "one_time" && form.distance !== null)) &&
      pickupLocation.name
    ) {
      estimateTripCharge();
    } else {
      setPrice(null);
    }
  }, [
    period,
    form.type,
    form.klass,
    form.distance,
    form.number_of_passengers,
    pickupLocation,
  ]);

  return (
    <div>
      <Grid stackable columns={2} style={{ marginBottom: 120 }}>
        <GridColumn>
          <div
            style={{
              flex: 1,
              paddingTop: 20,
              paddingBottom: 20,
              minHeight: 400,
              // marginRight: "4%",
              // marginLeft: "4%",
            }}
          >
            <Header style={{ fontSize: "3em", marginBottom: 48 }}>
              Luxury Ride with LUXTI
            </Header>

            <div>
              <Form
                size={formSize}
                loading={false}
                autoComplete="off"
                style={{}}
              >
                <Form.Input
                  fluid
                  // required
                  input={{ ref: pickupRef }}
                  size={formSize}
                  icon="map marker alternate"
                  // value={trip.pickup}
                  iconPosition="left"
                  autocomplete="off"
                  placeholder="Pickup Location"
                  onChange={(event, data) => {
                    if (data.value === "") {
                      setPickupLocation({
                        name: null,
                        type: "pickup",
                        latitude: null,
                        longitude: null,
                      });
                    }
                  }}
                  error={
                    validations.pickup
                      ? {
                          content: validations.pickup,
                        }
                      : false
                  }
                />

                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext
                    items={stopLocations}
                    strategy={verticalListSortingStrategy}
                  >
                    {stopLocations.map((location) => (
                      <SortableItem
                        key={location.id}
                        item={location}
                        places={places}
                        onDelete={handleRemoveStop}
                        onLocationUpdate={onStopLocationUpdate}
                      />
                    ))}
                  </SortableContext>
                </DndContext>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 0,
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "black",
                      fontWeight: 600,
                      cursor: "pointer",
                      marginRight: 10,
                      borderBottomWidth: 1,
                      borderBottomColor: "black",
                      borderBottomStyle: "groove",
                      display: "none",
                    }}
                  >
                    <Icon
                      name="plus"
                      style={{ margin: 0, fontSize: "0.9em" }}
                    />
                    <span
                      onClick={() => {
                        setStopLocations((state) => [
                          ...state,
                          {
                            id: uuidv4(),
                            name: "",
                            type: "stop",
                            latitude: null,
                            longitude: null,
                          },
                        ]);
                      }}
                    >
                      Add Stop with AI
                    </span>
                  </span>

                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "black",
                      fontWeight: 600,
                      cursor: "pointer",
                      borderBottomWidth: 1,
                      borderBottomColor: "black",
                      borderBottomStyle: "groove",
                    }}
                  >
                    <Icon
                      name="plus"
                      style={{ margin: 0, fontSize: "0.9em" }}
                    />
                    <span
                      onClick={() => {
                        setStopLocations((state) => [
                          ...state,
                          {
                            id: uuidv4(),
                            name: "",
                            type: "stop",
                            latitude: null,
                            longitude: null,
                          },
                        ]);
                      }}
                    >
                      Add Stop
                    </span>
                  </span>
                </div>

                {type === "one_time" && (
                  <Form.Input
                    fluid
                    // required
                    input={{ ref: dropoffRef }}
                    size={formSize}
                    icon="map pin"
                    // value={dropoff}
                    iconPosition="left"
                    autocomplete="off"
                    placeholder="Dropoff Location"
                    // autoComplete="new-password"
                    onChange={(event, data) => {
                      if (data.value === "") {
                        setDropoffLocation({
                          name: null,
                          type: "dropoff",
                          latitude: null,
                          longitude: null,
                        });
                      }
                    }}
                    // type="text"
                    error={
                      validations.dropoff
                        ? {
                            content: validations.dropoff,
                          }
                        : false
                    }
                  />
                )}

                {type === "hourly" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 16,
                      fontSize: "1.8em",
                      height: 49,
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Icon
                        name="hourglass one"
                        style={{ fontSize: "0.7em" }}
                      />
                      <Dropdown
                        inline
                        scrolling
                        value={period}
                        defaultValue={period}
                        onChange={(event, data) => {
                          setPeriod(data.value);

                          applyChanges({
                            trip_hours_attributes: [
                              { days: 1, hours: data.value },
                            ],
                          });
                        }}
                        style={{
                          fontSize: "0.8em",
                          marginRight: 12,
                          zIndex: 10000,
                        }}
                        options={periodOptions}
                      ></Dropdown>
                    </div>
                  </div>
                )}

                <ButtonGroup widths="2" style={{ marginBottom: 18 }}>
                  <Button
                    fluid
                    size={formSize}
                    active={type === "one_time"}
                    onClick={() => {
                      setType("one_time");
                      setDisplayDistance(null);
                      applyChanges({ type: "one_time", distance: null });
                    }}
                  >
                    One Time
                  </Button>
                  <Button
                    fluid
                    size={formSize}
                    active={type === "hourly"}
                    onClick={() => {
                      setType("hourly");
                      setDisplayDistance(null);
                      applyChanges({ type: "hourly", distance: null });
                    }}
                  >
                    By Hour
                  </Button>
                </ButtonGroup>

                <Form.Group widths="equal">
                  <div class="field">
                    <DatePicker
                      value={selectedDay}
                      shouldHighlightWeekends
                      minimumDate={dateToday}
                      onChange={(value) => {
                        setSelectedDay(value);

                        const { day, month, year } = value;

                        var local = DateTime.local(year, month, day, 0, 0, 0);

                        applyChanges({
                          pickup_date: local.toLocaleString({
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }),
                        });
                      }}
                      style={{ display: "block" }}
                      renderInput={({ ref }) => (
                        <Form.Input
                          fluid
                          input={{ ref }}
                          onFocus={() => setShowCalendar(true)}
                          size={formSize}
                          icon="calendar"
                          value={form.pickup_date}
                          iconPosition="left"
                          autocomplete="off"
                          placeholder="When"
                          // autoComplete="new-password"
                          onChange={(event) => {
                            // setPickupDate(event.target.value);
                          }}
                          type="text"
                          error={
                            validations.pickup_date
                              ? {
                                  content: validations.pickup_date,
                                }
                              : false
                          }
                        />
                      )}
                      // shouldHighlightWeekends
                      colorPrimary="black" // added this
                      colorPrimaryLight="rgba(75, 207, 250, 0.4)"
                    />
                  </div>

                  <div class="field">
                    <Form.Input
                      fluid
                      size={formSize}
                      icon="time"
                      value={form.pickup_time}
                      iconPosition="left"
                      autocomplete="off"
                      placeholder="Time"
                      // autoComplete="new-password"
                      // onChange={(event) => {
                      //   setPickupTime(event.target.value);
                      // }}
                      onBlur={() => {
                        setTimeout(() => {
                          setShowPickupTime(false);
                        }, 200);
                      }}
                      onFocus={() => setShowPickupTime(true)}
                      type="text"
                      error={
                        validations.pickup_time
                          ? {
                              content: validations.pickup_time,
                            }
                          : false
                      }
                    />
                    {showPickupTime && (
                      <ul
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          background: "white",
                          boxShadow: "rgb(124 124 124 / 18%) 0px 0px 12px 2px",
                          borderRadius: "8px",
                          marginTop: "4px",
                          overflow: "auto",
                          height: 130,
                          width: "-webkit-fill-available",
                          zIndex: 1000,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {timeOptions.map((time) => {
                          return (
                            <li
                              key={time}
                              className="pickup-time"
                              style={{
                                fontWeight:
                                  form.pickup_time === time ? "bold" : "normal",
                              }}
                              onClick={() =>
                                applyChanges({ pickup_time: time })
                              }
                            >
                              {time}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </Form.Group>

                <div
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "bold",
                    color: "#6c6c6c",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>All prices include fees, tolls and child seats</div>

                  {displayDistance && (
                    <div>
                      <span>{displayDistance}</span>
                    </div>
                  )}
                </div>

                <Message
                  icon
                  size="mini"
                  style={{
                    paddingLeft: 12,
                    paddingRight: 0,
                    marginTop: 0,
                    cursor: "pointer",
                  }}
                >
                  <MessageContent
                    style={{
                      fontSize: "2em",
                      // textAlign: "right",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {!expanded && (
                      <div
                        onClick={() => setExpanded(!expanded)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginRight: 24 }}>
                            <span style={{ fontSize: "0.8em" }}>
                              {klassDisplay}
                            </span>
                          </div>
                          <div style={{ marginRight: 12 }}>
                            <Icon name="users" />
                            <span style={{ fontSize: "0.8em" }}>
                              {form.number_of_passengers}
                            </span>
                          </div>

                          <div>
                            <Icon name="suitcase" />
                            <span style={{ fontSize: "0.8em" }}>
                              {form.number_of_luggages}
                            </span>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          {loading ? (
                            <Loading
                              width={100}
                              height={100}
                              style={{
                                position: "absolute",
                                top: -27,
                                right: 0,
                              }}
                            />
                          ) : (
                            <div style={{ marginRight: 4 }}>{price}</div>
                          )}

                          <Icon
                            name="edit"
                            style={{
                              fontSize: "0.8em",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {expanded && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Dropdown
                            inline
                            scrolling
                            options={klassOptions}
                            value={form.klass}
                            defaultValue={form.klass}
                            style={{ fontSize: "0.8em", marginRight: 12 }}
                            onChange={(event, data) => {
                              const record = klassOptions.find(
                                (k) => k.value === data.value
                              );

                              const { value, text, options } = record;
                              const defaultOption = options[0];

                              applyChanges({
                                klass: value,
                                number_of_luggages: defaultOption.luggages,
                                number_of_passengers: defaultOption.passengers,
                              });

                              onKlassDisplay(text);

                              onPassengerOptions(
                                options.reduce((array, option) => {
                                  array.push({
                                    key: option.passengers,
                                    text: option.passengers,
                                    value: option.passengers,
                                  });

                                  return array;
                                }, [])
                              );
                            }}
                          />

                          <Icon name="users" style={{ marginRight: 4 }} />
                          <Dropdown
                            inline
                            scrolling
                            value={form.number_of_passengers}
                            defaultValue={form.number_of_passengers}
                            options={passengerOptions}
                            onChange={(event, data) => {
                              const record = klassOptions.find(
                                (k) => k.value === form.klass
                              );

                              const { value, options } = record;
                              const option = options.find(
                                (k) => k.passengers === data.value
                              );

                              applyChanges({
                                number_of_luggages: option.luggages,
                                number_of_passengers: option.passengers,
                              });
                            }}
                            style={{ fontSize: "0.8em", marginRight: 12 }}
                          />

                          <Icon name="suitcase" />
                          <span
                            style={{
                              fontSize: "0.8em",
                              fontWeight: "bold",
                              marginRight: 12,
                              marginLeft: 2,
                            }}
                          >
                            {form.number_of_luggages}
                          </span>
                        </div>

                        <div
                          style={{ display: "flex" }}
                          onClick={() => setExpanded(false)}
                        >
                          {loading ? (
                            <Loading
                              width={100}
                              height={100}
                              style={{
                                position: "absolute",
                                top: -27,
                                right: 0,
                              }}
                            />
                          ) : (
                            <div style={{ marginRight: 4 }}>{price}</div>
                          )}

                          <Icon
                            name="check"
                            style={{
                              fontSize: "0.8em",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </MessageContent>
                </Message>

                <div style={{ marginTop: 24, textAlign: "center" }}>
                  <span>By proceeding, you agree to </span>
                  <a
                    href="/terms"
                    target="_blank"
                    style={{
                      color: "black",
                      textDecoration: "underline",
                    }}
                  >
                    terms of service
                  </a>
                </div>

                <div style={{ marginTop: 8 }}>
                  <Button
                    fluid
                    compact
                    type="submit"
                    color="black"
                    size="large"
                    loading={loading || submitting}
                    autocomplete="off"
                    style={{ padding: 16 }}
                    disabled={false}
                    onClick={handleBookNow}
                  >
                    Book Now
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </GridColumn>

        <GridColumn style={{ paddingTop: 100 }}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Map
              id="map"
              className="luxti-map"
              defaultZoom={zoom}
              defaultCenter={center}
              disableDefaultUI={true}
              city={[
                {
                  latitude: form.pickup_latitude,
                  longitude: form.pickup_longitude,
                },
              ]}
              style={{ borderRadius: 80, backgroundColor: "red" }}
            >
              {pickupPosition && stopLocations.length === 0 && (
                <Marker position={pickupPosition} />
              )}
              {/* {dropoffPosition && <Marker position={dropoffPosition} />} */}
            </Map>
          </div>
        </GridColumn>
      </Grid>

      {false && (
        <Grid>
          <GridColumn>
            <CityToCity />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export default Order;
{
  /* <Marker position={position} /> */
}

// {true && (
//   <Icon
//     name="circle notched"
//     loading
//     style={{ fontSize: "2em" }}
//   />
// )}
