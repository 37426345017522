import { get, fetch, create, update } from "app/api";

export const getMeRequest = (data) =>
  get({
    endpoint: "/api/v1/me",
    data,
  });

export const fetchPublicKeysRequest = (data) =>
  fetch({
    endpoint: "/api/v1/public/keys",
    data,
  });

export const createSessionRequest = (data) =>
  create({
    endpoint: "/api/v1/sessions",
    data,
  });

export const createVerifyTokenRequest = (data) =>
  create({
    endpoint: "/api/v1/auth/tokens",
    data,
  });

export const createRegisterRequest = (data) =>
  create({
    endpoint: "/api/v1/registrations",
    data,
  });

export const fetchCardsRequest = (data) =>
  fetch({
    endpoint: "/api/v1/cards",
    data,
  });

export const fetchPaymentMethodsRequest = (data) =>
  fetch({
    endpoint: "/api/v1/payment_methods",
    data,
  });

export const getTripRequest = (id) =>
  get({
    endpoint: `/api/v1/trips/${id}`,
  });

export const fetchTripsRequest = (data) =>
  fetch({
    endpoint: "/api/v1/trips",
    data,
  });

export const createTripRequest = (data) =>
  create({
    endpoint: "/api/v1/trips",
    data,
  });

export const updateTripRequest = (id, data) =>
  update({
    endpoint: `/api/v1/trips/${id}`,
    data,
  });

export const confirmTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/confirm`,
  });

export const declineTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/decline`,
  });

export const cancelTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/cancel`,
  });

// Driver requests
export const startTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/start`,
  });

export const arriveTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/arrive`,
  });

export const pickupTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/pickup`,
  });

export const waitTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/wait`,
  });

export const finishTripRequest = (trip_id) =>
  update({
    endpoint: `/api/v1/trips/${trip_id}/finish`,
  });

export const estimateTripFeeRequest = (data) =>
  create({
    endpoint: "/api/v1/fees/trips",
    data,
  });

export const estimateTripRequest = (data) =>
  create({
    endpoint: "/api/v1/estimates/trips",
    data,
  });

export const getChargeRequest = (id) =>
  get({
    endpoint: `/api/v1/charges/${id}`,
  });

export const fetchChargesRequest = (data) =>
  fetch({
    endpoint: "/api/v1/charges",
    data,
  });

export const createChargeRequest = (data) =>
  create({
    endpoint: "/api/v1/charges",
    data,
  });

export const updateChargeRequest = (id, data) =>
  update({
    endpoint: `/api/v1/charges/${id}`,
    data,
  });

export const createChargePaymentIntentRequest = (charge_id) =>
  create({
    endpoint: `/api/v1/charges/${charge_id}/payment_intents`,
  });

export const createTripPaymentIntentRequest = (trip_id) =>
  create({
    endpoint: `/api/v1/trips/${trip_id}/payment_intent`,
  });

export const createSetupIntentRequest = (data) =>
  create({
    endpoint: `/api/v1/setup_intents`,
    data,
  });

export const fetchPassengersRequest = (data) =>
  fetch({
    endpoint: `/api/v1/passengers`,
    data,
  });

export const createPassengerRequest = (data) =>
  create({
    endpoint: `/api/v1/passengers`,
    data,
  });

export const updatePassengerRequest = (id, data) =>
  update({
    endpoint: `/api/v1/passengers/${id}`,
    data,
  });

export const createTripDriversRequest = (id, data) =>
  create({
    endpoint: `/api/v1/trips/${id}/drivers`,
    data,
  });

export const createTripPassengersRequest = (id, data) =>
  create({
    endpoint: `/api/v1/trips/${id}/passengers`,
    data,
  });

export const fetchDriversRequest = (data) =>
  fetch({
    endpoint: `/api/v1/drivers`,
    data,
  });

export const updateAdminTripRequest = (id, data) =>
  update({
    endpoint: `/api/v1/admin/trips/${id}`,
    data,
  });

export const getHourRequest = (id) =>
  get({
    endpoint: `/api/v1/hour_requests/${id}`,
  });

export const createHourRequest = (data) =>
  create({
    endpoint: `/api/v1/hour_requests`,
    data,
  });

export const getLocationRequest = (id) =>
  get({
    endpoint: `/api/v1/location_requests/${id}`,
  });

export const createLocationRequest = (data) =>
  create({
    endpoint: `/api/v1/location_requests`,
    data,
  });

export const prepareProcessPaymentRequest = (id) =>
  update({
    endpoint: `/api/v1/process_payments/${id}/prepare`,
  });

export const settleProcessPaymentRequest = (id) =>
  update({
    endpoint: `/api/v1/process_payments/${id}/settle`,
  });

export const verifyUsersRequest = (data) =>
  create({
    endpoint: `/api/v1/verify/users`,
    data,
  });

export const createValidationsRequest = (data) =>
  create({
    endpoint: `/api/v1/validations`,
    data,
  });
